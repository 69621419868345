import Container from '@mui/material/Container';
import { Grid, Stack } from '@mui/material';
import HeaderMenuDDI from '../header/HeaderMenuDDI';
import { useLocation } from "react-router-dom";
import Footer from '../footer/Footer';
import RoundedPlainCardsLuz from '../card/luz/RoundedPlainCardsLuz';
import HeadingLuz from '../heading/HeadingLuz';
import Heading from '../heading/Heading';
import ComplexCardsLuz from '../card/luz/ComplexCardsLuz';
import QuestionsLuz from '../questions/QuestionsLuz';
import StickyContainer from '../container/StickyContainer';
import MobileAnchor from '../button/MobileAnchor';
import Helmet from "react-helmet"
import snippet from '../assets/data/richSnippet.json';
import { useDDI } from '../utils/GetDDI';
import StickyC2CBottom from '../button/StickyC2CBottom';
import HeaderBanner from '../banner/HeaderBanner';
import BannerImageTextReversedLuz from '../banner/BannerImageTextReversedLuz';
import StickyFormMobile from '../button/StickyFormMobile';

function Luz() {
  const { search } = useLocation();
  const ddi = useDDI();

  return (
    <>
      <Helmet>
        <title>Contrata la mejor tarifa Luz y ahorra   | Repsol </title>
        <meta name="description" content="Contrata ahora la mejor orferta de luz para tu casa con Repsol. Paga siempre lo mismo con el precio fijo de luz, tarifa plana y el 50% descuento durante 10h o domingos GRATIS. Garantizamos el mejor precio"/>
        <script type="application/ld+json">
          {JSON.stringify(snippet.luz)}
        </script>    
      </Helmet>
      <Stack>
        <StickyFormMobile/>
        <StickyC2CBottom/>
        <StickyContainer>
            <HeaderMenuDDI link={`/${search}`} ddi={ddi} />
        </StickyContainer>
        <Container maxWidth="false" disableGutters>
          <HeaderBanner props={{ marginStack: '10% 10% 10% 10%', marginStackReduced: '5% 10% 10% 10%' }} titleLine1="¿Hablamos?"
            page= "luz"
            titleMobile2="TÚ DECIDES"
            titleMobile3="Luz a precio fijo, tarifa plana, 10 horas al 50% de dto. o domingos GRATIS"
            imageMobile={require('../assets/images/bannerLuzMobile.png')}
            imageDesktop={require('../assets/images/bannerLuz.svg').default} />
        </Container>
        <Container>
          <MobileAnchor />
        </Container>
        <Container maxWidth="false" disableGutters>
          <RoundedPlainCardsLuz />
        </Container>
        <Container id="tarifas"  container maxWidth="false" disableGutters sx={{display:'flex', justifyContent:'center'}} alignItems="stretch" >
          <Grid item xs={10.5} md={10} spacing={{ xs: 1, lg: 1 }}   sx={{justifyContent:{ xs:'center', md:'space-between'}}}  justifyContent="space-between">
            <Heading text="Hay muchas formas de ahorrar en tu factura de luz, ¡elige la tuya!" />
          </Grid>
        </Container>
          <ComplexCardsLuz />
        <Container maxWidth="false" disableGutters>
          <BannerImageTextReversedLuz ddi={ddi} imageMobile={require('../assets/images/banner-text-luz-mobile.png')} imageDesktop={require('../assets/images/banner-text-luz.png')} />
        </Container>
        <Container container maxWidth="false" disableGutters sx={{display:'flex', marginBottom: '60px', justifyContent:'center'}} alignItems="stretch">
          <Grid item xs={10.5} md={10} spacing={{ xs: 1, lg: 1 }}   sx={{justifyContent:{ xs:'center', md:'space-between'}}}  justifyContent="space-between">
            <HeadingLuz text="No queremos que te quedes con dudas, ¡somos la solución!" />
            <QuestionsLuz />
          </Grid>
        </Container>
      </Stack>
      <Footer />
    </>
  );
}

export default Luz;
