import {Stack, Grid, Container} from '@mui/material';
import HeaderMenuDDI from '../header/HeaderMenuDDI';
import { useLocation } from "react-router-dom";
import HeaderBanner from '../banner/HeaderBanner';
import Footer from '../footer/Footer';
import RoundedPlainCardsHome from '../card/RoundedPlainCardsHome';
import HeadingLuz from '../heading/HeadingLuz';
import Heading from '../heading/Heading';
import ComplexCardsHome from '../card/ComplexCardsHome';
import QuestionsHome from '../questions/QuestionsHome';
import BannerImageForm from '../banner/BannerImageForm';
import BannerImageFormComparador from '../banner/BannerImageFormComparador';
import StickyContainer from '../container/StickyContainer';
import MobileAnchor from '../button/MobileAnchor';
import Helmet from "react-helmet"
import snippet from '../assets/data/richSnippet.json';
import { useDDI } from '../utils/GetDDI';
import BannerImageTextReversed from '../banner/BannerImageTextReversed';
import StickyC2CBottom from '../button/StickyC2CBottom';
import StickyFormMobile from '../button/StickyFormMobile';

function Home() {
  const { search } = useLocation();
  const ddi = useDDI();
  return (
    <>
      <Helmet>
        <title>Ofertas de luz y gas al mejor precio | Repsol </title>
        <meta name="description" content="Contrata la tarifa de luz o gas que mejor se adapta a tus necesidades y empieza a ahorrar con Repsol. Paga siempre lo mismo con la tarifa plana, consigue descuentos y premios con nuestra app y disfruta de asistencia las 24h del día"/>
        <script type="application/ld+json">
          {JSON.stringify(snippet.home)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(snippet.all)}
        </script>
      </Helmet>
      <Stack>
        <StickyFormMobile/>
        <StickyC2CBottom/>
        <StickyContainer>
            <HeaderMenuDDI link={`/${search}`} ddi={ddi} />
        </StickyContainer>
        <Container maxWidth="false" disableGutters>
          <HeaderBanner props={{ marginStack: '10% 10% 10% 10%', marginStackReduced: '5% 10% 10% 10%' }} titleLine1="¿Hablamos?"
            page= "home"
            titleMobile="TARIFAS DE"
            titleMobile2="LUZ Y GAS"
            titleMobile3="PARA TU HOGAR"
            imageMobile={require('../assets/images/bannerHomeMobile.png')}
            imageDesktop={require('../assets/images/bannerHome.svg').default} />
        </Container>
        <Container>
          <MobileAnchor />
        </Container>
        <Container maxWidth="false" disableGutters>
          <RoundedPlainCardsHome />
        </Container>
        <Container maxWidth="false" disableGutters>
          <BannerImageFormComparador imageMobile={require('../assets/images/banner-form-comparador-mobile.png')} imageDesktop={require('../assets/images/banner-form-comparador.png')} />
        </Container>
        <Container id="tarifas"  container maxWidth="false" disableGutters sx={{display:'flex', justifyContent:'center'}} alignItems="stretch" >
          <Grid item xs={10.5} md={10} spacing={{ xs: 1, lg: 1 }}   sx={{justifyContent:{ xs:'center', md:'space-between'}}}  justifyContent="space-between">
            <Heading text="Nuestras mejores tarifas de luz y gas para ahorrar" />
          </Grid>
        </Container>
          <ComplexCardsHome/>
        <Container maxWidth="false" disableGutters>
          <BannerImageTextReversed ddi={ddi} imageMobile={require('../assets/images/banner-bateria-mobile.png')} imageDesktop={require('../assets/images/banner-bateria.png')} />
        </Container>
        <Container maxWidth="false" disableGutters>
          <BannerImageForm imageMobile={require('../assets/images/banner-form-mobile.png')} imageDesktop={require('../assets/images/banner-form.png')} />
        </Container>
        <Container container maxWidth="false" disableGutters sx={{display:'flex', marginBottom: '60px', justifyContent:'center'}} alignItems="stretch">
          <Grid item xs={10.5} md={10} spacing={{ xs: 1, lg: 1 }}   sx={{justifyContent:{ xs:'center', md:'space-between'}}}  justifyContent="space-between">
            <HeadingLuz text="No queremos que te quedes con dudas, ¡somos la solución!" />
            <QuestionsHome />
          </Grid>
        </Container>
      </Stack>
      <Footer />
    </>
  );
}

export default Home;
