import Container from '@mui/material/Container';
import { Stack, Grid } from '@mui/material';
import HeaderMenuDDI from '../header/HeaderMenuDDI';
import { useLocation } from "react-router-dom";
import Footer from '../footer/Footer';
import RoundedPlainCardsGas from '../card/RoundedPlainCardsGas';
import HeadingLuz from '../heading/HeadingLuz';
import QuestionsGas from '../questions/QuestionsGas';
import ComplexCardsGas from '../card/ComplexCardsGas';
import useWindowSize from '../utils/WindowSize';
import StickyContainer from '../container/StickyContainer';
import MobileAnchor from '../button/MobileAnchor';
import Helmet from "react-helmet"
import snippet from '../assets/data/richSnippet.json';
import { useDDI } from '../utils/GetDDI';
import StickyC2CBottom from '../button/StickyC2CBottom';
import HeaderBanner from '../banner/HeaderBanner';
import StickyFormMobile from '../button/StickyFormMobile';

function Gas() {
  const [width] = useWindowSize();
  const { search } = useLocation();
  const ddi = useDDI();

  return (
    <>
      <Helmet>
        <title>Contrata la mejor tarifa Gas | Repsol</title>
        <meta name="description" content="Contrata la mejor tarifa de Gas con Repsol y empieza a ahorrar. Energía 100% renovable para tu hogar al mejor precio. Controla tu consumo en tiempo real con la app y GRATIS el mantenimiento de electrodomésticos"/>
        <script type="application/ld+json">
          {JSON.stringify(snippet.gas)}
        </script>
      </Helmet>
      <Stack>
        <StickyFormMobile />
        <StickyC2CBottom />
        <StickyContainer>
            <HeaderMenuDDI link={`/${search}`} ddi={ddi} />
        </StickyContainer>
        <Container maxWidth="false" disableGutters>
        <HeaderBanner props={{ marginStack: '10% 10% 10% 10%', marginStackReduced: '5% 10% 10% 10%' }} titleLine1="¿Hablamos?"
          page= "gas"
          titleMobile="TU GAS SIEMPRE AL"
          titleMobile2="MISMO PRECIO"
          titleMobile3="¡Y CON MANTENIMIENTO DE TUS ELECTRODOMÉSTICOS GRATIS!"
          imageMobile={require('../assets/images/bannerGasMobile.png')}
          imageDesktop={require('../assets/images/bannerGas.svg').default} />
        </Container>
        <Container>
          <MobileAnchor />
        </Container>
        <Container maxWidth="false" disableGutters>
          <RoundedPlainCardsGas />
        </Container>
        <Container id="tarifas"  container maxWidth="false" disableGutters sx={{display:{xs:'flex', md:'none'}, justifyContent:'center'}} alignItems="stretch" >
          <Grid item xs={10.5} md={10} spacing={{ xs: 1, lg: 1 }}   sx={{justifyContent:{ xs:'center', md:'space-between'}}}  justifyContent="space-between">
            <HeadingLuz text="Es el momento de empezar a ahorrar" />
          </Grid>
        </Container>
          <ComplexCardsGas />
          <Container container maxWidth="false" disableGutters sx={{display:'flex', marginBottom: '60px', justifyContent:'center'}} alignItems="stretch">
            <Grid item xs={10.5} md={10} spacing={{ xs: 1, lg: 1 }}   sx={{justifyContent:{ xs:'center', md:'space-between'}}}  justifyContent="space-between">
             <HeadingLuz text="No queremos que te quedes con dudas, ¡somos la solución!" />
              <QuestionsGas />
            </Grid>
        </Container>
      </Stack>
      <Footer />
    </>
  );
}

export default Gas;
