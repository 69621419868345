import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { TextField, Typography, FormControlLabel, Button, Stack, Container, Grid } from '@mui/material';
import ColoredCheckbox from '../checkbox/ColoredCheckbox';
import { useLocation } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { isPhoneValid } from '../utils/Functions';
import { launchC2C } from '../utils/PostLead';
import CallingDialog from '../dialog/CallingDialog';
import PrivacyDialog from '../dialog/PrivacyDialog';
import MuiLink  from '@mui/material/Link';
import TextHeaderHome from '../texts/TextHeaderHome';
import TextHeaderLuz from '../texts/TextHeaderLuz';
import TextHeaderGas from '../texts/TextHeaderGas';
import TextHeaderLuzGas from '../texts/TextHeaderLuzGas';

const useStyles = makeStyles({
  input: {
    outline: 'none !important',
    border: '1px solid red',
    boxShadow: '0 0 10px #719ECE',
  },
  stack: {
    margin: props => props.marginStack,
    textAlign:'left',
    '@media (max-width:1300px)': {
      margin: '0% 5% 0% 5%!important'
    },
    '@media (max-width:900px)': {
      margin: '10% 10% auto 10%'
    },
    '@media (max-width:500px)': {
      margin: '0% 0% 0% 0%!important',
      background:'white',
      padding:'5%',
      borderRadius:'37px'
    },
  },
  stackHeader: {
    '@media (max-width:1300px)': {
      marginTop: '7%'
    },
  },
  stackHeaderMobile: {
    '@media (max-width:500px)': {
      marginBottom:'20px',
      marginTop:'10px',
      '& h2': {
        fontSize:'35px',
        lineHeight:'28px',
        marginTop:'5px'
      },
      '& h3':{
        fontSize:'17px',
        lineHeight:'14px',
      },
      '& h4':{
        fontSize:'22px',
        lineHeight:'22px',
        marginTop:'5px'
      }
    },
  },
  stackTimetable: {
    fontSize: '16px',
    '@media (max-width:1300px)': {
      fontSize: '14px',
    },
    '@media (max-width:500px)': {
      fontSize: '12px',
    }
     
  },
  box: {
    width: '35%',
    background: '#FFFFFF 0% 0% no-repeat padding-box', 
    boxShadow: 'inset 2px 2px 25px #FFFFFF, 0px 3px 6px #00000029',
    borderRadius: '37px',
    position: 'absolute',
    float: 'right',
    top: '10%',
    right: '2%',
    '@media (max-width:1300px)': {
      width: '45%',
      height:'90%',
      top: '5%',
    },
    '@media (max-width:1199px)': {
      height:'auto',
      position: 'relative',
      width: '80%',
      margin: '0 auto',
      float: 'none',
      right: '0px',
      top: '0%',
      marginTop:'0%',
      marginBottom:'5%'
    },
    '@media (max-width:500px)': {
      position: 'absolute',
      width: '90%',
      marginTop: '60%',
      margin: '5% 5%',
      bottom: 0,
      display: 'flex',
      alignItems: 'flex-end',

      background:'transparent',
      boxShadow:'none'
    },
  },
  button: {
    fontSize: '22px',
    padding:'9px',
    fontFamily: 'Galano Grotesque Bold, Arial',
    '&:hover': {
      backgroundColor: '#F39D0A',
    },
    '@media (max-width:1300px)': {
      marginBottom: '7%'
    },
  },
  a: {
    textDecoration: 'none !important',
  }
});

function HeaderBanner({page, titleLine1, titleLine2, imageDesktop, imageMobile, titleMobile, titleMobile2, titleMobile3, props}) {
  const classes = useStyles(props);

  const { search } = useLocation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isOpenCallingDialog, setIsOpenCallingDialog] = useState(false);
	const [isOpenPrivacyDialog, setIsOpenPrivacyDialog] = useState(false);


  const handleClose = () => {
    setChecked(false);
    clearPhone();
    setButtonClicked(false);
  };

  const handleCloseCallingDialog = () => {
    setIsOpenCallingDialog(false);
    setButtonClicked(false);
    handleClose();
  };
  const handleClosePrivacyDialog = () => {
    setIsOpenPrivacyDialog(false);
  };

  const handleCheckboxChange = (event) => {
    setChecked(!checked);
  }

  const handleTextFieldChange = (event) => {
    setPhoneNumber(event.target.value);
  }

  const handleClickOpen = () => {
    setIsOpenCallingDialog(true);
    
  };
  const handleClicPrivacy = () => {
    setIsOpenPrivacyDialog(true);
  };

  const clearPhone = () => { 
    setPhoneNumber("");
    setChecked(true);
    setButtonClicked(false);
  }
  return (
    <>
      <CallingDialog
        open={isOpenCallingDialog}
        onClose={handleCloseCallingDialog}
      />
      <PrivacyDialog
        open={isOpenPrivacyDialog}
        onClose={handleClosePrivacyDialog}
      />
    <Container disableGutters maxWidth="false" sx={{justifyContent:'center', display:'flex'}} >
      <Grid sx={{ position: 'relative', justifyContent:{xs:'flex-start' ,md:'center'}}} container xs={10.5} sm={10} >
        {page ==="home" && <TextHeaderHome  titleMobile={titleMobile} titleMobile2={titleMobile2} titleMobile3={titleMobile3}/>}
        {page ==="luz" && <TextHeaderLuz  titleMobile={titleMobile} titleMobile2={titleMobile2} titleMobile3={titleMobile3}/>}
        {page ==="gas" && <TextHeaderGas  titleMobile={titleMobile} titleMobile2={titleMobile2} titleMobile3={titleMobile3}/>}
        {page ==="luzygas" && <TextHeaderLuzGas  titleMobile={titleMobile} titleMobile2={titleMobile2} titleMobile3={titleMobile3}/>}
        <picture>
          <source media="(max-width: 500px)" srcSet={imageMobile} />
          <img width="100%" height="auto" src={imageDesktop} alt="banner" />
        </picture>
        <Box className={classes.box}>
          <Stack className={classes.stack}>
            <Typography className={classes.stackHeader} variant="h4">{titleLine1}{<br/>}{titleLine2}</Typography>
            <Typography className={classes.stackTimetable} variant="body2">Nuestro horario es de lunes a viernes de 9 a 21h</Typography>
            <TextField value={phoneNumber} onChange={handleTextFieldChange} size="small" sx={{ width: '100%', marginTop: '1%' }} label={<Typography sx={{marginTop: '4px'}} variant="body2">Escribe tu teléfono</Typography>} variant="outlined" />
            <FormControlLabel sx={{ marginTop: '10px', marginBottom: '5px', alignItems:'self-start'  }} control={<ColoredCheckbox value={checked} onChange={handleCheckboxChange}/>} label={<Typography variant="subtitle2" sx={{lineHeight: '1.1', color:'#041E42'}}>He leído y acepto la <MuiLink 
            onClick={() => {
              handleClicPrivacy();
            }} 
            style={{ fontWeight: '600', color: '#041E42' }}>política de privacidad</MuiLink> y consiento el tratamiento de mis datos personales para todas las finalidades contenidas en la misma</Typography>}/>
            {!checked && buttonClicked && <Typography variant="subtitle2" color="red">Debes aceptar la política de privacidad</Typography>}
            {checked && buttonClicked && !isPhoneValid(phoneNumber) && <Typography variant="subtitle2" color="red">El número de teléfono no es válido</Typography>}
            <Button className={classes.button}  color="white" variant="subtitle1"
              onClick={() => {
                setButtonClicked(true);
                if (checked && isPhoneValid(phoneNumber)) {
                  launchC2C(phoneNumber);
                  handleClickOpen();
                }
              }}
              sx={{
              background: '#FF8200 0% 0% no-repeat padding-box',
              borderRadius: '10px',
              height: '55px',
              width: '100%',
              color:'white'
            }}>
              LLÁMAME GRATIS
            </Button>
          </Stack>
        </Box>
      </Grid>
    </Container>
    </>
  )
}

export default HeaderBanner;